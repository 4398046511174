import DataEditor from "@components/DataEditor"
import React, { ReactElement } from "react"
import { connect } from "redux-zero/react"
import Network from "src/utils/Network"
import Button from "@components/Button"
import DateTime from "@utils/DateTime"

interface ISearchBarProps {
  rootLocation?: any
  onSearchClick?: any
  searchQuery?: any
  spread?: boolean
  onSpread?: any
}

function SearchBar({
  rootLocation = {},
  onSearchClick,
  searchQuery,
  spread = false,
  onSpread,
}: ISearchBarProps): ReactElement {
  const [query, setQuery] = React.useState({} as any)
  const [pickupList, setPickupList] = React.useState([] as any)
  const [dropoffList, setDropoffList] = React.useState([] as any)
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState("")
  const { location } = rootLocation
  let tid: any

  React.useEffect(() => {
    setQuery({})
  }, [rootLocation])
  React.useEffect(() => {
    setQuery({ ...searchQuery })
  }, [searchQuery])

  const getSchema = () => [
    {
      name: "pickupPlace",
      title: "Pickup Place",
      type: "SEARCH_INPUT",
      flexBasis: "50%",
      icon: fetching
        ? require("@images/loading.gif")
        : require("@images/search_icon.svg"),
      list: pickupList,
      onSearchInputChange: onPlacesSearchInputChange(setPickupList),
    },
    {
      name: "dropoffPlace",
      title: "Dropoff Place",
      type: "SEARCH_INPUT",
      flexBasis: "50%",
      icon: fetching
        ? require("@images/loading.gif")
        : require("@images/search_icon.svg"),
      list: dropoffList,
      onSearchInputChange: onPlacesSearchInputChange(setDropoffList),
    },
    {
      name: "pickupDate",
      title: "Pickup Date/Time",
      type: "DATE_TIME",
      flexBasis: "33%",
      icon: require("@images/calendar_icon.svg"),
    },
    {
      name: "dropoffDate",
      title: "Dropoff Date/Time",
      type: "DATE_TIME",
      flexBasis: "33%",
      icon: require("@images/calendar_icon.svg"),
    },
    {
      name: "submit",
      title: "Find Car",
      type: "BUTTON",
      styleType: "primary",
      flexBasis: "34%",
      onClick: () => {
        if (onSearchClick && validate(query)) {
          onSearchClick(query)
        }
      },
    },
  ]

  const onChange = (d: any) => {
    setQuery(d)
  }

  const queryPlaces = async (text: string) => {
    setFetching(true)
    try {
      const { data } = await Network.post("places/search", {
        searchText: text,
        latlong: location
          ? location.latlong
          : process.env.DEFAULT_ROOT_LOCATION,
      })
      if (data.meta && data.meta.code === 200) {
        const { response } = data
        return (
          (response.groups.length &&
            response.groups[0].items.map((i: any) => i.venue)) ||
          []
        )
      }
    } catch (e) {
      return []
    } finally {
      setFetching(false)
    }
  }

  const onPlacesSearchInputChange = (setListFn: any) => async (val: any) => {
    clearTimeout(tid)
    tid = setTimeout(async () => {
      if (val.length > 2 && !fetching) {
        const res = await queryPlaces(val)
        if (res && res.length > 0) {
          setListFn(
            res.map((i: any) => ({
              name: i.name,
            }))
          )
        }
      }
    }, 100)
  }

  const validate = (query: any) => {
    setError("")
    if (!query.pickupPlace || query.pickupPlace === "") {
      setError("Please select a Pickup place!")
      return false
    }
    if (!query.dropoffPlace || query.dropoffPlace === "") {
      setError("Please select a Drop-Off place!")
      return false
    }
    if (!query.pickupDate || query.pickupDate === "") {
      setError("Please select a Pickup Date!")
      return false
    }
    if (!query.dropoffDate || query.dropoffDate === "") {
      setError("Please select a Drop-Off Date!")
      return false
    }
    return true
  }

  return (
    <>
      <div className={spread ? " md:block" : "hidden md:block "}>
        {error && error.length ? (
          <div className="bg-yellow-500 text-yellow-800 p-2 px-4 m-2 rounded-full">
            {error}
          </div>
        ) : (
          ""
        )}

        <div className="flex w-full">
          <div className="flex p-2 w-full">
            <DataEditor
              schema={getSchema() as any}
              data={query}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div onClick={onSpread} className={spread ? " " : "block md:hidden "}>
        {spread ? (
          <>{/* <Button>Done</Button> */}</>
        ) : (
          <>
            <div className="cursor-pointer">
              <div>
                <span>
                  <b>Pickup</b>{" "}
                  <span className="text-gray-500 ml-2">
                    {query.pickupPlace}
                  </span>{" "}
                </span>
                <br />
                <span>
                  <b>Dropoff</b>{" "}
                  <span className="text-gray-500 ml-2">
                    {query.dropoffPlace}
                  </span>{" "}
                </span>
              </div>
              <div className="text-xs my-2">
                <span className="text-gray-500">
                  {DateTime.Moment(query.pickupDate).format("LLL")}
                </span>{" "}
                to &nbsp;
                <span className="text-gray-500">
                  {DateTime.Moment(query.dropoffDate).format("LLL")}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

const mapToProps = (state: any) => ({
  rootLocation: state.rootLocation,
})

export default connect(mapToProps)(SearchBar)
