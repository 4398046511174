import SearchBar from "@hoc/SearchBar"
import CommonUtils from "@utils/CommonUtils"
import React, { ReactElement } from "react"
import Network from "@utils/Network"
import VehicleList from "@hoc/VehicleList"
import { navigate } from "gatsby"
import { IVehicleListItem } from "@hoc/VehicleList/VehicleListItem"
import { Store } from "@utils/Store"
const styles = require("./index.module.css")

function SearchContainer(): ReactElement {
  const [items, setItems] = React.useState([] as any)
  const [query, setQuery] = React.useState({} as any)
  const [fetching, setFetching] = React.useState(true)
  const [searchBarSpread, setSearchBarSpread] = React.useState(false)
  React.useEffect(() => {
    extractQuery()
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      search()
    }, 50)
  }, [query])

  const extractQuery = () => {
    const params = CommonUtils.queryStringToJSON(
      window.location.search.substr(1)
    )
    setQuery(params)
  }

  const onSearchClick = (query: any) => {
    setSearchBarSpread(false)
    setQuery(query)
    const queryString = CommonUtils.jsonToQueryString(
      JSON.parse(JSON.stringify(query))
    )
    navigate("/search?" + queryString)
  }

  const search = async () => {
    setFetching(true)
    try {
      const { data } = await Network.get("vehicles")
      if (data && data.length) {
        setItems(transform(data))
      }
    } catch (e) {
    } finally {
      setTimeout(() => setFetching(false), 300)
    }
  }

  const transform = (data: any) => {
    return data.map((d: any) => {
      const _d = { ...d }
      _d.availableFrom = query.pickupDate
      _d.availableTill = query.dropoffDate
      _d.pickupPlace = query.pickupPlace
      _d.dropoffPlace = query.dropoffPlace
      return { ..._d }
    })
  }

  const onSearchSpread = () => {
    setSearchBarSpread(!searchBarSpread)
  }

  const onBookClick = (vehicle: IVehicleListItem) => {
    Store.setState({
      checkout__cart: [vehicle],
    })
    navigate("/checkout")
  }

  return (
    <div>
      <div className="relative">
        <div
          className={styles.bg + " absolute w-full top-0  bg-blue-500"}
        ></div>
        <div className="max-w-1366 mx-auto flex w-full">
          <div className="mt-16 bg-gray-200 w-full rounded-lg shadow-lg p-4">
            <SearchBar
              spread={searchBarSpread}
              onSpread={onSearchSpread}
              searchQuery={query}
              onSearchClick={onSearchClick}
            />
          </div>
        </div>
      </div>
      <div className="max-w-1366 mx-auto flex w-full p-8 md:p-0">
        {fetching ? (
          <div className="flex flex-col items-center w-full mt-16">
            <div className=" text-center text-gray-500">
              {" "}
              Searching you ride...
            </div>
            <img className="w-16" src={require("@images/loading.gif")} />
          </div>
        ) : (
          <div className="mt-16 w-full p-4">
            <VehicleList items={items} onSelect={onBookClick} />
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchContainer
