import SearchContainer from "@containers/Search"
import DefaultLayout from "@layouts/default"
import React, { ReactElement } from "react"

function Search(): ReactElement {
  return (
    <>
      <DefaultLayout pageTitle="Home">
        <SearchContainer />
      </DefaultLayout>
    </>
  )
}

export default Search
