import React, { ReactElement } from "react"
import VehicleListItem, { IVehicleListItem } from "./VehicleListItem"
interface IVehicleListProps {
  items?: IVehicleListItem[]
  onSelect?: any
}

function VehicleList({ items, onSelect }: IVehicleListProps): ReactElement {
  return (
    <>
      {items && items.length ? (
        <>
          {items.map((item: IVehicleListItem) => (
            <VehicleListItem item={item} onBookNowClick={onSelect} />
          ))}
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default VehicleList
